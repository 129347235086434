import React from "react";
import Inmoadal from "../components/Inmoadal";

const PoliticasPrivacidad = () => {
  return (
    <>
      <div className="container">
        <h3>POLÍTICA de PRIVACIDAD</h3>
        <h3>“SUS DATOS SEGUROS”</h3>
        <p>
          Información en cumplimiento de la normativa de protección de datos
          personales
        </p>
        <p>
          En Europa y en España existen normas de protección de datos pensadas
          para proteger su información personal de obligado cumplimiento para
          nuestra entidad.
        </p>
        <p>
          Por ello, es muy importante para nosotros que entienda perfectamente
          qué vamos a hacer con los datos personales que le pedimos.
        </p>
        <p>
          Así, seremos transparentes y le daremos el control de sus datos, con
          un lenguaje sencillo y opciones claras que le permitirán decidir qué
          haremos con su información personal.
        </p>
        <p>
          Por favor, si una vez leída la presente información le queda alguna
          duda, no dude en preguntarnos.
        </p>
        <p>Muchas gracias por su colaboración.</p>
        <ul>
          <li>
            <p>
              <strong>¿Quiénes somos?</strong>
            </p>
            <ul>
              <li>
                Nuestra denominación: ALINEA AGB DESARROLLOS y PROYECTOS, S.L.U.
              </li>
              <li>Nuestro CIF: B01641638</li>
              <li>
                Nuestra actividad principal: Servicios inmobiliarios, asesoría
                financiera y seguros
              </li>
              <li>
                Nuestra dirección: C/ Arquitecto Morell Nº 27 - Bajo, 03003,
                Alicante
              </li>
              <li>Nuestro teléfono de contacto: 965362858</li>
              <li>
                Nuestra dirección de correo electrónico de contacto:
                info@wereal.es
              </li>
              <li>
                Nuestra página web:{" "}
                <a href="http://www.wereal.es">www.wereal.es</a>
              </li>
            </ul>
          </li>
          <li>
            <p>
              <strong>¿Para qué vamos a usar sus datos?</strong>
            </p>
            <p>
              Con carácter general, sus datos personales serán usados para poder
              relacionarnos con usted y poder prestarle nuestros servicios.
              Asimismo, también pueden ser usados para otras actividades, como
              enviarle publicidad o promocionar nuestras actividades.
            </p>
          </li>
          <li>
            <p>
              <strong>¿Por qué necesitamos usar sus datos?</strong>
            </p>
            <p>
              Sus datos personales son necesarios para poder relacionarnos con
              usted y poder prestarle nuestros servicios. En este sentido,
              pondremos a su disposición una serie de casillas que le permitirán
              decidir de manera clara y sencilla sobre el uso de su información
              personal.
            </p>
          </li>
          <li>
            <p>
              <strong>
                {" "}
                ¿Quién va a conocer la información que le pedimos?
              </strong>
            </p>
            <p>
              Con carácter general, sólo el personal de nuestra entidad que esté
              debidamente autorizado podrá tener conocimiento de la información
              que le pedimos. De igual modo, podrán tener conocimiento de su
              información personal aquellas entidades que necesiten tener acceso
              a la misma para que podamos prestarle nuestros servicios. Así por
              ejemplo, nuestro banco conocerá sus datos si el pago de nuestros
              servicios se realiza mediante tarjeta o transferencia bancaria.
              Asimismo, tendrán conocimiento de su información aquellas
              entidades públicas o privadas a las cuales estemos obligados a
              facilitar sus datos personales con motivo del cumplimiento de
              alguna ley. Poniéndole un ejemplo, la Ley Tributaria obliga a
              facilitar a la Agencia Tributaria determinada información sobre
              operaciones económicas que superen una determinada cantidad. En el
              caso de que, al margen de los supuestos comentados, necesitemos
              dar a conocer su información personal a otras entidades, le
              solicitaremos previamente su permiso a través de opciones claras
              que le permitirán decidir a este respecto.
            </p>
          </li>
          <li>
            <p>
              <strong> ¿Cómo vamos a proteger sus datos?</strong>
            </p>
            <p>
              Protegeremos sus datos con medidas de seguridad eficaces en
              función de los riesgos que conlleve el uso de su información. Para
              ello, nuestra entidad ha aprobado una Política de Protección de
              Datos y se realizan controles y auditorías anuales para verificar
              que sus datos personales están seguros en todo momento.
            </p>
          </li>
          <li>
            <p>
              <strong> ¿Enviaremos sus datos a otros países?</strong>
            </p>
            <p>
              En el mundo hay países que son seguros para sus datos y otros que
              no lo son tanto. Así por ejemplo, la Unión Europea es un entorno
              seguro para sus datos. Nuestra política es no enviar su
              información personal a ningún país que no sea seguro desde el
              punto de vista de la protección de sus datos. En el caso de que,
              con motivo de prestarle el servicio, sea imprescindible enviar sus
              datos a un país que no sea tan seguro como España, siempre le
              solicitaremos previamente su permiso y aplicaremos medidas de
              seguridad eficaces que reduzcan los riesgos del envío de su
              información personal a otro país.
            </p>
          </li>
          <li>
            <p>
              <strong>
                {" "}
                ¿Durante cuánto tiempo vamos a conservar sus datos?
              </strong>
            </p>
            <p>
              Conservaremos sus datos durante nuestra relación y mientras nos
              obliguen las leyes. Una vez finalizados los plazos legales
              aplicables, procederemos a eliminarlos de forma segura y
              respetuosa con el medio ambiente.
            </p>
          </li>
          <li>
            <p>
              <strong> ¿Cuáles son sus derechos de protección de datos?</strong>
            </p>
            <p>
              En cualquier momento puede dirigirse a nosotros para saber qué
              información tenemos sobre usted, rectificarla si fuese incorrecta
              y eliminarla una vez finalizada nuestra relación, en el caso de
              que ello sea legalmente posible. También tiene derecho a solicitar
              el traspaso de su información a otra entidad. Este derecho se
              llama “portabilidad” y puede ser útil en determinadas situaciones.
              Para solicitar alguno de estos derechos, deberá realizar una
              solicitud escrita a nuestra dirección, junto con una fotocopia de
              su DNI, para poder identificarle. En las oficinas de nuestra
              entidad disponemos de formularios específicos para solicitar
              dichos derechos y le ofrecemos nuestra ayuda para su
              cumplimentación. Para saber más sobre sus derechos de protección
              de datos, puede consultar la página web de la Agencia Española de
              Protección de Datos (www.agpd.es).
            </p>
          </li>
          <li>
            <strong>
              {" "}
              ¿Puede retirar su consentimiento si cambia de opinión en un
              momento posterior?
            </strong>
            <p>
              Usted puede retirar su consentimiento si cambia de opinión sobre
              el uso de sus datos en cualquier momento. Así por ejemplo, si
              usted en su día estuvo interesado/a en recibir publicidad de
              nuestros productos o servicios, pero ya no desea recibir más
              publicidad, puede hacérnoslo constar a través del formulario de
              oposición al tratamiento disponible en las oficinas de nuestra
              entidad.
            </p>
          </li>
          <li>
            <strong>
              En caso de que entienda que sus derechos han sido desatendidos,
              ¿dónde puede formular una reclamación?
            </strong>
            <p>
              En caso de que entienda que sus derechos han sido desatendidos por
              nuestra entidad, puede formular una reclamación en la Agencia
              Española de Protección de Datos, a través de alguno de los medios
              siguientes:
              <ul>
                <li>
                  Sede electrónica: <a href="www.agpd.es">www.agpd.es</a>
                </li>
                <li>
                  Dirección postal: Agencia Española de Protección de Datos, C/
                  Jorge Juan Nº 6, 28001, Madrid
                </li>
                <li>Vía telefónica: Telf. 901 100 099 / Telf. 912 663 517</li>
              </ul>
              Formular una reclamación en la Agencia Española de Protección de
              Datos no conlleva ningún coste y no es necesaria la asistencia de
              abogado ni procurador.
            </p>
          </li>
          <li>
            <strong>¿Elaboraremos perfiles sobre usted?</strong>
            <p>
              Nuestra política es no elaborar perfiles sobre los usuarios de
              nuestros servicios. No obstante, pueden existir situaciones en las
              que, con fines de prestación del servicio, comerciales o de otro
              tipo, necesitemos elaborar perfiles de información sobre usted. Un
              ejemplo pudiera ser la utilización de su historial de compras o
              servicios para poder ofrecerle productos o servicios adaptados a
              sus gustos o necesidades. En tal caso, aplicaremos medidas de
              seguridad eficaces que protejan su información en todo momento de
              personas no autorizadas que pretendan utilizarla en su propio
              beneficio.
            </p>
          </li>
          <li>
            <strong>¿Usaremos sus datos para otros fines?</strong>
            <p>
              Nuestra política es no usar sus datos para otras finalidades
              distintas a las que le hemos explicado. Si, no obstante,
              necesitásemos usar sus datos para actividades distintas, siempre
              le solicitaremos previamente su permiso a través de opciones
              claras que le permitirán decidir al respecto.
            </p>
          </li>
        </ul>
      </div>
      <Inmoadal />
    </>
  );
};

export default PoliticasPrivacidad;
