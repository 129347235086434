import React from "react";
import Inmoadal from "../components/Inmoadal";

const AvisoLegal = () => {
  return (
    <>
      <div className="container">
        <section>
          <h3>
            INFORMACIÓN en CUMPLIMIENTO de lo ESTABLECIDO en la LEY 34/2002 de
            11 de JULIO de SERVICIOS de la SOCIEDAD de la INFORMACIÓN y de
            COMERCIO ELECTRÓNICO
          </h3>
          <p>
            En cumplimiento de lo establecido en el artículo 10 de la Ley
            34/2002, de 11 de julio, de Servicios de la Sociedad de la
            Información y de Comercio Electrónico (en adelante, LSSI-CE), se
            informa de modo expreso, preciso e inequívoco, tanto a los
            destinatarios del servicio como a los órganos competentes, de los
            siguientes aspectos relativos al prestador de servicios de la
            sociedad de la información:
          </p>
          <ul>
            <li>
              Denominación social: ALINEA AGB DESARROLLOS y PROYECTOS, S.L.U.
            </li>
            <li>CIF: B01641638</li>
            <li>
              Objeto social: Servicios inmobiliarios, asesoría financiera y
              seguros
            </li>
            <li>
              Dirección: C/ Arquitecto Morell Nº 27 - Bajo, 03003, Alicante
            </li>
            <li>Teléfono: 965362858</li>
            <li>Email: info@wereal.es</li>
          </ul>

          <h3>POLÍTICA de PRIVACIDAD</h3>
          <p>
            “SUS DATOS SEGUROS” Información en cumplimiento de la normativa de
            protección de datos personales
          </p>
          <p>
            En Europa y en España existen normas de protección de datos pensadas
            para proteger su información personal de obligado cumplimiento para
            nuestra entidad. Por ello, es muy importante para nosotros que
            entienda perfectamente qué vamos a hacer con los datos personales
            que le pedimos. Así, seremos transparentes y le daremos el control
            de sus datos, con un lenguaje sencillo y opciones claras que le
            permitirán decidir qué haremos con su información personal. Por
            favor, si una vez leída la presente información le queda alguna
            duda, no dude en preguntarnos. Muchas gracias por su colaboración.
          </p>
          <h6>• ¿Quiénes somos?</h6>
          <ul>
            <li>
              Nuestra denominación: ALINEA AGB DESARROLLOS y PROYECTOS, S.L.U.
            </li>
            <li>Nuestro CIF: B01641638</li>
            <li>
              Nuestra actividad principal: Servicios inmobiliarios, asesoría
              financiera y seguros
            </li>
            <li>
              Nuestra dirección: C/ Arquitecto Morell Nº 27 - Bajo, 03003,
              Alicante
            </li>
            <li>Nuestro teléfono de contacto: 965362858</li>
            <li>
              Nuestra dirección de correo electrónico de contacto:
              info@wereal.es
            </li>
            <li>
              Nuestra página web: <a href="www.wereal.es">www.wereal.es</a>
            </li>
          </ul>

          <h6>• ¿Para qué vamos a usar sus datos?</h6>
          <p>
            Con carácter general, sus datos personales serán usados para poder
            relacionarnos con usted y poder prestarle nuestros servicios.
            Asimismo, también pueden ser usados para otras actividades, como
            enviarle publicidad o promocionar nuestras actividades.
          </p>
          <h6>• ¿Quién va a conocer la información que le pedimos?</h6>
          <p>
            Con carácter general, sólo el personal de nuestra entidad que esté
            debidamente autorizado podrá tener conocimiento de la información
            que le pedimos. De igual modo, podrán tener conocimiento de su
            información personal aquellas entidades que necesiten tener acceso a
            la misma para que podamos prestarle nuestros servicios. Así por
            ejemplo, nuestro banco conocerá sus datos si el pago de nuestros
            servicios se realiza mediante tarjeta o transferencia bancaria.
            Asimismo, tendrán conocimiento de su información aquellas entidades
            públicas o privadas a las cuales estemos obligados a facilitar sus
            datos personales con motivo del cumplimiento de alguna ley.
            Poniéndole un ejemplo, la Ley Tributaria obliga a facilitar a la
            Agencia Tributaria determinada información sobre operaciones
            económicas que superen una determinada cantidad. En el caso de que,
            al margen de los supuestos comentados, necesitemos dar a conocer su
            información personal a otras entidades, le solicitaremos previamente
            su permiso a través de opciones claras que le permitirán decidir a
            este respecto.
          </p>
          <h6>• ¿Cómo vamos a proteger sus datos?</h6>
          <p>
            Protegeremos sus datos con medidas de seguridad eficaces en función
            de los riesgos que conlleve el uso de su información. Para ello,
            nuestra entidad ha aprobado una Política de Protección de Datos y se
            realizan controles y auditorías anuales para verificar que sus datos
            personales están seguros en todo momento.
          </p>

          <h6>• ¿Enviaremos sus datos a otros países?</h6>
          <p>
            En el mundo hay países que son seguros para sus datos y otros que no
            lo son tanto. Así por ejemplo, la Unión Europea es un entorno seguro
            para sus datos. Nuestra política es no enviar su información
            personal a ningún país que no sea seguro desde el punto de vista de
            la protección de sus datos. En el caso de que, con motivo de
            prestarle el servicio, sea imprescindible enviar sus datos a un país
            que no sea tan seguro como España, siempre le solicitaremos
            previamente su permiso y aplicaremos medidas de seguridad eficaces
            que reduzcan los riesgos del envío de su información personal a otro
            país.
          </p>

          <h6>• ¿Durante cuánto tiempo vamos a conservar sus datos?</h6>
          <p>
            Conservaremos sus datos durante nuestra relación y mientras nos
            obliguen las leyes. Una vez finalizados los plazos legales
            aplicables, procederemos a eliminarlos de forma segura y respetuosa
            con el medio ambiente.
          </p>
          <h6>• ¿Cuáles son sus derechos de protección de datos?</h6>
          <p>
            En cualquier momento puede dirigirse a nosotros para saber qué
            información tenemos sobre usted, rectificarla si fuese incorrecta y
            eliminarla una vez finalizada nuestra relación, en el caso de que
            ello sea legalmente posible. También tiene derecho a solicitar el
            traspaso de su información a otra entidad. Este derecho se llama
            “portabilidad” y puede ser útil en determinadas situaciones. Para
            solicitar alguno de estos derechos, deberá realizar una solicitud
            escrita a nuestra dirección, junto con una fotocopia de su DNI, para
            poder identificarle. En las oficinas de nuestra entidad disponemos
            de formularios específicos para solicitar dichos derechos y le
            ofrecemos nuestra ayuda para su cumplimentación. Para saber más
            sobre sus derechos de protección de datos, puede consultar la página
            web de la Agencia Española de Protección de Datos (
            <a href="www.agpd.es">www.agpd.es</a>).
          </p>

          <h6>
            • ¿Puede retirar su consentimiento si cambia de opinión en un
            momento posterior?
          </h6>
          <p>
            Usted puede retirar su consentimiento si cambia de opinión sobre el
            uso de sus datos en cualquier momento. Así por ejemplo, si usted en
            su día estuvo interesado/a en recibir publicidad de nuestros
            productos o servicios, pero ya no desea recibir más publicidad,
            puede hacérnoslo constar a través del formulario de oposición al
            tratamiento disponible en las oficinas de nuestra entidad.
          </p>

          <h6>
            En caso de que entienda que sus derechos han sido desatendidos,
            ¿dónde puede formular una reclamación?
          </h6>
          <p>
            En caso de que entienda que sus derechos han sido desatendidos por
            nuestra entidad, puede formular una reclamación en la Agencia
            Española de Protección de Datos, a través de alguno de los medios
            siguientes:
            <ul>
              <li>
                Sede electrónica: <a href="www.agpd.es">www.agpd.es</a>
              </li>
              <li>
                Dirección postal: Agencia Española de Protección de Datos C/
                Jorge Juan Nº 6, 28001, Madrid
              </li>
              <li>Vía telefónica: Telf. 901 100 099 / Telf. 912 663 517</li>
            </ul>
            Formular una reclamación en la Agencia Española de Protección de
            Datos no conlleva ningún coste y no es necesaria la asistencia de
            abogado ni procurador.
          </p>

          <h6>• ¿Elaboraremos perfiles sobre usted?</h6>
          <p>
            Nuestra política es no elaborar perfiles sobre los usuarios de
            nuestros servicios. No obstante, pueden existir situaciones en las
            que, con fines de prestación del servicio, comerciales o de otro
            tipo, necesitemos elaborar perfiles de información sobre usted. Un
            ejemplo pudiera ser la utilización de su historial de compras o
            servicios para poder ofrecerle productos o servicios adaptados a sus
            gustos o necesidades. En tal caso, aplicaremos medidas de seguridad
            eficaces que protejan su información en todo momento de personas no
            autorizadas que pretendan utilizarla en su propio beneficio.
          </p>

          <h6>• ¿Usaremos sus datos para otros fines?</h6>
          <p>
            Nuestra política es no usar sus datos para otras finalidades
            distintas a las que le hemos explicado. Si, no obstante,
            necesitásemos usar sus datos para actividades distintas, siempre le
            solicitaremos previamente su permiso a través de opciones claras que
            le permitirán decidir al respecto.
          </p>
        </section>

        <section>
          <h3>COMPROMISO de PROTECCIÓN de DATOS</h3>
          <h3>“PERSONAS INFORMADAS y DATOS PROTEGIDOS”</h3>

          <p>
            La Dirección/Órgano de Gobierno de ALINEA AGB DESARROLLOS y
            PROYECTOS, S.L.U. (en adelante, el responsable del tratamiento),
            asume la máxima responsabilidad y compromiso con el establecimiento,
            implementación y mantenimiento de la presente Política de Protección
            de Datos, garantizando la mejora continua del responsable del
            tratamiento con el objetivo de alcanzar la excelencia en relación
            con el cumplimiento del Reglamento (UE) 2016/679 del Parlamento
            Europeo y del Consejo, de 27 de abril de 2016, relativo a la
            protección de las personas físicas en lo que respecta al tratamiento
            de datos personales y a la libre circulación de estos datos y por el
            que se deroga la Directiva 95/46/CE (Reglamento general de
            protección de datos) (DOUE L 119/1, 04-05-2016), y de la normativa
            española de protección de datos de carácter personal (Ley Orgánica,
            legislación sectorial específica y sus normas de desarrollo).
          </p>

          <p>
            La Política de Protección de Datos de ALINEA AGB DESARROLLOS y
            PROYECTOS, S.L.U. descansa en el principio de responsabilidad
            proactiva, según el cual el responsable del tratamiento es
            responsable del cumplimiento del marco normativo y jurisprudencial
            que gobierna dicha Política, y es capaz de demostrarlo ante las
            autoridades de control competentes.
          </p>

          <p>
            En tal sentido, el responsable del tratamiento se regirá por los
            siguientes principios que deben servir a todo su personal como guía
            y marco de referencia en el tratamiento de datos personales:
          </p>

          <ol>
            <li>
              <strong>Protección de datos desde el diseño:</strong> el
              responsable del tratamiento aplicará, tanto en el momento de
              determinar los medios de tratamiento como en el momento del propio
              tratamiento, medidas técnicas y organizativas apropiadas, como la
              seudonimización, concebidas para aplicar de forma efectiva los
              principios de protección de datos, como la minimización de datos,
              e integrar las garantías necesarias en el tratamiento.
            </li>
            <li>
              <strong>Protección de datos por defecto:</strong> el responsable
              del tratamiento aplicará las medidas técnicas y organizativas
              apropiadas con miras a garantizar que, por defecto, solo sean
              objeto de tratamiento los datos personales que sean necesarios
              para cada uno de los fines específicos del tratamiento.
            </li>
            <li>
              <strong>
                Protección de datos en el ciclo de vida de la información:
              </strong>{" "}
              las medidas que garanticen la protección de los datos personales
              serán aplicables durante el ciclo completo de la vida de la
              información.
            </li>
            <li>
              <strong>Licitud, lealtad y transparencia:</strong> los datos
              personales serán tratados de manera lícita, leal y transparente en
              relación con el interesado.
            </li>
            <li>
              <strong>Limitación de la finalidad:</strong> los datos personales
              serán recogidos con fines determinados, explícitos y legítimos, y
              no serán tratados ulteriormente de manera incompatible con dichos
              fines.
            </li>
            <li>
              <strong>Minimización de datos:</strong> los datos personales serán
              adecuados, pertinentes y limitados a lo necesario en relación con
              los fines para los que son tratados.
            </li>
            <li>
              <strong>Exactitud:</strong> los datos personales serán exactos y,
              si fuera necesario, actualizados; se adoptarán todas las medidas
              razonables para que se supriman o rectifiquen sin dilación los
              datos personales que sean inexactos con respecto a los fines para
              los que se tratan.
            </li>
            <li>
              <strong>Limitación del plazo de conservación:</strong> los datos
              personales serán mantenidos de forma que se permita la
              identificación de los interesados durante no más tiempo del
              necesario para los fines del tratamiento de los datos personales.
            </li>
            <li>
              <strong>Integridad y confidencialidad:</strong> los datos
              personales serán tratados de tal manera que se garantice una
              seguridad adecuada de los datos personales, incluida la protección
              contra el tratamiento no autorizado o ilícito y contra su pérdida,
              destrucción o daño accidental, mediante la aplicación de medidas
              técnicas u organizativas apropiadas.
            </li>
            <li>
              <strong>Información y formación:</strong> una de las claves para
              garantizar la protección de los datos personales es la formación e
              información que se facilite al personal involucrado en el
              tratamiento de los mismos. Durante el ciclo de vida de la
              información, todo el personal con acceso a los datos será
              convenientemente formado e informado acerca de sus obligaciones en
              relación con el cumplimiento de la normativa de protección de
              datos.
            </li>
          </ol>

          <p>
            La Política de Protección de Datos de ALINEA AGB DESARROLLOS y
            PROYECTOS, S.L.U. es comunicada a todo el personal del responsable
            del tratamiento y puesta a disposición de todas las partes
            interesadas.
          </p>

          <p>
            En su consecuencia, la presente Política de Protección de Datos
            involucra a todo el personal del responsable del tratamiento, que
            debe conocerla y asumirla, considerándola como propia, siendo cada
            miembro responsable de aplicarla y de verificar las normas de
            protección de datos aplicables a su actividad, así como identificar
            y aportar las oportunidades de mejora que considere oportunas con el
            objetivo de alcanzar la excelencia en relación con su cumplimiento.
          </p>

          <p>
            Esta Política será revisada por la Dirección/Órgano de Gobierno de
            ALINEA AGB DESARROLLOS y PROYECTOS, S.L.U., tantas veces como se
            considere necesario, para adecuarse, en todo momento, a las
            disposiciones vigentes en materia de protección de datos de carácter
            personal.
          </p>
        </section>
        <section>
          <h3>POLÍTICA de COOKIES</h3>
          <p>
            Bienvenida/o a la POLÍTICA de COOKIES de la página web de la entidad
            ALINEA AGB DESARROLLOS y PROYECTOS, S.L.U., provista de CIF
            B01641638, donde te explicaremos en un lenguaje claro y sencillo
            todas las cuestiones necesarias para que puedas tener el control
            sobre ellas en base a tus decisiones personales.
          </p>

          <h6>¿QUÉ SON LAS COOKIES Y PARA QUÉ LAS USAMOS?</h6>
          <p>
            Una cookie o galleta informática es un pequeño archivo de
            información que se guarda en tu ordenador, “smartphone” o tableta
            cada vez que visitas nuestra página web.
          </p>
          <p>
            En principio, una cookie es inofensiva: no contiene virus, troyanos,
            gusanos, etc. que puedan dañar tu terminal, pero sí tiene cierto
            impacto sobre tu derecho a la protección de tus datos personales,
            pues recoge determinada información concerniente a tu persona
            (hábitos de navegación, identidad, preferencias, etc.).
          </p>
          <p>
            Es por ello que, en base a lo establecido en la normativa aplicable
            (LSSI y normativa vigente de protección de datos personales), la
            activación de determinados tipos de cookies necesitará de tu
            autorización previa.
          </p>
          <p>
            Antes de ello, te daremos alguna información adicional que te
            ayudará a una mejor toma de decisiones al respecto:
          </p>

          <h6>
            1. Las cookies pueden ser de varios tipos en función de su
            finalidad:
          </h6>
          <ul>
            <li>
              Las cookies técnicas son necesarias para que nuestra página web
              pueda funcionar, no necesitan de tu autorización y son las únicas
              que tenemos activadas por defecto.
            </li>
          </ul>

          <h6>
            2. Algunas cookies son nuestras (las denominaremos cookies propias)
            y otras pertenecen a empresas externas que prestan servicios para
            nuestra página web (las denominaremos cookies de terceros):
          </h6>
          <p>
            En este sentido, es importante que sepas que algunos de dichos
            proveedores externos pueden estar ubicados fuera de España. A nivel
            mundial, no todos los países tienen un mismo nivel de protección de
            datos, existiendo algunos países más seguros que otros (por ejemplo,
            la Unión Europea es un entorno seguro para tus datos).
          </p>

          <h6>
            3. Finalmente, indicarte que, en función del plazo de tiempo que
            permanecen activas, las cookies pueden ser de dos tipos:
          </h6>
          <ul>
            <li>
              - <strong>Cookies de sesión:</strong> expiran automáticamente
              cuando terminas la sesión en tu ordenador, “smartphone” o tableta.
              Suelen emplearse para conservar la información necesaria mientras
              se te presta un servicio en una sola ocasión.
            </li>
            <li>
              - <strong> Cookies persistentes:</strong> permanecen almacenadas
              en tu ordenador, “smartphone” o tableta durante un periodo
              determinado, que puede variar desde unos minutos hasta varios
              años.
            </li>
          </ul>

          <h6>¿QUÉ TIPO DE COOKIES PODEMOS UTILIZAR EN NUESTRA PÁGINA WEB?</h6>
          <p>
            - <strong> Cookies Técnicas</strong> : Las cookies técnicas son
            estrictamente necesarias para que nuestra página web funcione y
            puedas navegar por la misma. Este tipo de cookies son las que, por
            ejemplo, nos permiten identificarte, darte acceso a determinadas
            partes restringidas de la página si fuese necesario, o recordar
            diferentes opciones o servicios ya seleccionados por ti, como tus
            preferencias de privacidad. Por ello,
            <strong>
              están activadas por defecto, no siendo necesaria tu autorización
              al respecto.
            </strong>{" "}
            A través de la configuración de tu navegador, puedes bloquear o
            alertar de la presencia de este tipo de cookies, si bien dicho
            bloqueo afectará al correcto funcionamiento de las distintas
            funcionalidades de nuestra página web.
          </p>

          <p>
            -<strong>Cookies de Análisis:</strong> Las cookies de análisis nos
            permiten estudiar la navegación de los usuarios de nuestra página
            web en general (por ejemplo, qué secciones de la página son las más
            visitadas, qué servicios se usan más y si funcionan correctamente,
            etc.). A partir de la información estadística sobre la navegación en
            nuestra página web, podemos mejorar tanto el propio funcionamiento
            de la página como los distintos servicios que ofrece. Por tanto,
            estas cookies <strong>no tienen una finalidad publicitaria,</strong>{" "}
            sino que únicamente sirven para que nuestra página web funcione
            mejor, adaptándose a nuestros usuarios en general. Activándolas
            contribuirás a dicha mejora continua. <br />
            <strong>
              Puedes activar o desactivar estas cookies marcando la casilla
              correspondiente,
            </strong>{" "}
            estando desactivadas por defecto.
          </p>

          <p>
            - <strong>Cookies de Funcionalidad y Personalización:</strong> Las
            cookies de funcionalidad nos permiten recordar tus preferencias,
            para personalizar a tu medida determinadas características y
            opciones generales de nuestra página web, cada vez que accedas a la
            misma (por ejemplo, el idioma en que se te presenta la información,
            las secciones marcadas como favoritas, tu tipo de navegador, etc.).
            Por tanto, este tipo de cookies{" "}
            <strong>no tienen una finalidad publicitaria,</strong> sino que
            activándolas mejorarás la funcionalidad de la página web (por
            ejemplo, adaptándose a tu tipo de navegador) y la personalización de
            la misma en base a tus preferencias (por ejemplo, presentando la
            información en el idioma que hayas escogido en anteriores
            ocasiones), lo cual contribuirá a la facilidad, usabilidad y
            comodidad de nuestra página durante tu navegación. <br />
            <strong>
              Puedes activar o desactivar estas cookies marcando la casilla
              correspondiente,
            </strong>{" "}
            estando desactivadas por defecto.
          </p>
          <p>
            - <strong>Cookies de Publicidad:</strong> Las cookies de publicidad
            nos permiten la gestión de los espacios publicitarios incluidos en
            nuestra página web en base a criterios como el contenido mostrado o
            la frecuencia en la que se muestran los anuncios. Así, por ejemplo,
            si se te ha mostrado varias veces un mismo anuncio en nuestra página
            web, y no has mostrado un interés personal haciendo clic sobre él,
            este no volverá a aparecer. En resumen, activando este tipo de
            cookies, la publicidad mostrada en nuestra página web será más útil
            y diversa, y menos repetitiva. <br />
            <strong>
              Puedes activar o desactivar estas cookies marcando la casilla
              correspondiente,
            </strong>{" "}
            estando desactivadas por defecto.
          </p>
          <p>
            - <strong>Cookies de Publicidad Comportamental:</strong> Las cookies
            de publicidad comportamental nos permiten obtener información basada
            en la observación de tus hábitos y comportamientos de navegación en
            la web, a fin de poder mostrarte contenidos publicitarios que se
            ajusten mejor a tus gustos e intereses personales. Para que lo
            entiendas de manera muy sencilla, te pondremos un ejemplo ficticio:
            si tus últimas búsquedas en la web estuviesen relacionadas con
            literatura de suspense, te mostraríamos publicidad sobre libros de
            suspense. Por tanto, activando este tipo de cookies, la publicidad
            que te mostremos en nuestra página web no será genérica, sino que
            estará orientada a tus búsquedas, gustos e intereses, ajustándose
            por tanto exclusivamente a ti. <br />
            <strong>
              Puedes activar o desactivar estas cookies marcando la casilla
              correspondiente,
            </strong>{" "}
            estando desactivadas por defecto.
          </p>
          <h6>
            • ¿QUÉ TIPO DE COOKIES SE UTILIZAN ACTUALMENTE EN NUESTRA PÁGINA
            WEB?
          </h6>
          <p>
            A continuación, procedemos a informarte sobre todos los tipos de
            cookies que actualmente se utilizan en nuestra página web y la
            finalidad de cada una de las mismas.
            <br />-<strong>Cookies de Análisis:</strong> Las cookies de análisis
            nos permiten estudiar la navegación de los usuarios de nuestra
            página web en general (por ejemplo, qué secciones de la página son
            las más visitadas, qué servicios se usan más y si funcionan
            correctamente, etc.). <br />- <strong> Cookies Técnicas</strong> :
            Las cookies técnicas son estrictamente necesarias para que nuestra
            página web funcione y puedas navegar por la misma. Este tipo de
            cookies son las que, por ejemplo, nos permiten identificarte, darte
            acceso a determinadas partes restringidas de la página si fuese
            necesario, o recordar diferentes opciones o servicios ya
            seleccionados por ti, como tus preferencias de privacidad.
          </p>
          <h6>¿QUÉ PUEDES HACER CON LAS COOKIES?</h6>
          <p>
            Cuando accedes por primera vez a nuestra página web, se te muestra
            una ventana en la que te informamos que las cookies pueden ser de
            varios tipos:
          </p>
          <ul>
            <li>
              -Las cookies técnicas son necesarias para que nuestra página web
              pueda funcionar, no necesitan de tu autorización y son las únicas
              que tenemos activadas por defecto.
            </li>
            <li>
              - El resto de cookies sirven para mejorar nuestra página, para
              personalizarla en base a tus preferencias, o para poder mostrarte
              publicidad ajustada a tus búsquedas, gustos e intereses
              personales. Puedes aceptar todas estas cookies pulsando el botón
              ACEPTAR, rechazarlas pulsando el botón RECHAZAR o configurarlas
              clicando en el apartado CONFIGURACIÓN de COOKIES.
            </li>
          </ul>
          <p>
            Informarte también que, una vez que hayas activado cualquier tipo de
            cookies, tienes la posibilidad de desactivarlas en el momento que
            desees, con el simple paso de desmarcar la casilla correspondiente
            en el apartado CONFIGURACIÓN de COOKIES de nuestra página web.
            Siempre será tan sencillo para ti activar nuestras cookies propias
            como desactivarlas.
          </p>
          <p>
            Recordarte asimismo que, a través de la configuración de tu
            navegador, puedes bloquear o alertar de la presencia de cookies, si
            bien dicho bloqueo puede afectar al correcto funcionamiento de las
            distintas funcionalidades de nuestra página web en el caso de las
            cookies técnicas necesarias.
          </p>
          <p>
            Por último, indicarte que, si activas cookies de terceros (empresas
            externas que prestan servicios para nuestra página web) y
            posteriormente deseas desactivarlas, podrás hacerlo de dos formas:
            usando las herramientas de desactivación de cookies de tu navegador
            o a través de los propios sistemas habilitados por dichos
            proveedores externos.
          </p>
          <p>
            Para que te sea mucho más fácil, a continuación, te relacionamos una
            serie de enlaces a las pautas de desactivación de cookies de los
            navegadores de uso común:
          </p>
          <ul>
            <li>
              <a
                href="https://support.google.com/chrome/answer/95647?hl=es"
                target="_blank"
                rel="noreferrer"
              >
                Google Chrome
              </a>
            </li>
            <li>
              <a
                href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias"
                target="_blank"
                rel="noreferrer"
              >
                Mozilla Firefox
              </a>
            </li>
            <li>
              <a
                href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies"
                target="_blank"
                rel="noreferrer"
              >
                Internet Explorer
              </a>
            </li>
            <li>
              <a
                href="https://support.apple.com/es-es/guide/safari/sfri11471/mac"
                target="_blank"
                rel="noreferrer"
              >
                Safari
              </a>
            </li>
            <li>
              <a
                href="https://support.apple.com/es-es/HT201265"
                target="_blank"
                rel="noreferrer"
              >
                Safari para IOS (iPhone y iPad)
              </a>
            </li>
            <li>
              <a
                href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=es"
                target="_blank"
                rel="noreferrer"
              >
                Google Chrome para Android
              </a>
            </li>
            <li>
              <a
                href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DiOS&hl=es"
                target="_blank"
                rel="noreferrer"
              >
                Google Chrome para Iphone y Ipad
              </a>
            </li>
          </ul>
          <p>Muchas gracias por tu colaboración</p>
        </section>
      </div>
      <Inmoadal />
    </>
  );
};

export default AvisoLegal;
