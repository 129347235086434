import React from "react";
import Inmoadal from "../components/Inmoadal";

const PoliticaDeCookies = () => {
  return (
    <>
      <section className="container">
        <h3>POLÍTICA de COOKIES</h3>
        <p>
          Bienvenida/o a la POLÍTICA de COOKIES de la página web de la entidad
          ALINEA AGB DESARROLLOS y PROYECTOS, S.L.U., provista de CIF B01641638,
          donde te explicaremos en un lenguaje claro y sencillo todas las
          cuestiones necesarias para que puedas tener el control sobre ellas en
          base a tus decisiones personales.
        </p>

        <h6>¿QUÉ SON LAS COOKIES Y PARA QUÉ LAS USAMOS?</h6>
        <p>
          Una cookie o galleta informática es un pequeño archivo de información
          que se guarda en tu ordenador, “smartphone” o tableta cada vez que
          visitas nuestra página web.
        </p>
        <p>
          En principio, una cookie es inofensiva: no contiene virus, troyanos,
          gusanos, etc. que puedan dañar tu terminal, pero sí tiene cierto
          impacto sobre tu derecho a la protección de tus datos personales, pues
          recoge determinada información concerniente a tu persona (hábitos de
          navegación, identidad, preferencias, etc.).
        </p>
        <p>
          Es por ello que, en base a lo establecido en la normativa aplicable
          (LSSI y normativa vigente de protección de datos personales), la
          activación de determinados tipos de cookies necesitará de tu
          autorización previa.
        </p>
        <p>
          Antes de ello, te daremos alguna información adicional que te ayudará
          a una mejor toma de decisiones al respecto:
        </p>

        <h6>
          1. Las cookies pueden ser de varios tipos en función de su finalidad:
        </h6>
        <ul>
          <li>
            Las cookies técnicas son necesarias para que nuestra página web
            pueda funcionar, no necesitan de tu autorización y son las únicas
            que tenemos activadas por defecto.
          </li>
        </ul>

        <h6>
          2. Algunas cookies son nuestras (las denominaremos cookies propias) y
          otras pertenecen a empresas externas que prestan servicios para
          nuestra página web (las denominaremos cookies de terceros):
        </h6>
        <p>
          En este sentido, es importante que sepas que algunos de dichos
          proveedores externos pueden estar ubicados fuera de España. A nivel
          mundial, no todos los países tienen un mismo nivel de protección de
          datos, existiendo algunos países más seguros que otros (por ejemplo,
          la Unión Europea es un entorno seguro para tus datos).
        </p>

        <h6>
          3. Finalmente, indicarte que, en función del plazo de tiempo que
          permanecen activas, las cookies pueden ser de dos tipos:
        </h6>
        <ul>
          <li>
            - <strong>Cookies de sesión:</strong> expiran automáticamente cuando
            terminas la sesión en tu ordenador, “smartphone” o tableta. Suelen
            emplearse para conservar la información necesaria mientras se te
            presta un servicio en una sola ocasión.
          </li>
          <li>
            - <strong> Cookies persistentes:</strong> permanecen almacenadas en
            tu ordenador, “smartphone” o tableta durante un periodo determinado,
            que puede variar desde unos minutos hasta varios años.
          </li>
        </ul>

        <h6>¿QUÉ TIPO DE COOKIES PODEMOS UTILIZAR EN NUESTRA PÁGINA WEB?</h6>
        <p>
          - <strong> Cookies Técnicas</strong> : Las cookies técnicas son
          estrictamente necesarias para que nuestra página web funcione y puedas
          navegar por la misma. Este tipo de cookies son las que, por ejemplo,
          nos permiten identificarte, darte acceso a determinadas partes
          restringidas de la página si fuese necesario, o recordar diferentes
          opciones o servicios ya seleccionados por ti, como tus preferencias de
          privacidad. Por ello,
          <strong>
            están activadas por defecto, no siendo necesaria tu autorización al
            respecto.
          </strong>{" "}
          A través de la configuración de tu navegador, puedes bloquear o
          alertar de la presencia de este tipo de cookies, si bien dicho bloqueo
          afectará al correcto funcionamiento de las distintas funcionalidades
          de nuestra página web.
        </p>

        <p>
          -<strong>Cookies de Análisis:</strong> Las cookies de análisis nos
          permiten estudiar la navegación de los usuarios de nuestra página web
          en general (por ejemplo, qué secciones de la página son las más
          visitadas, qué servicios se usan más y si funcionan correctamente,
          etc.). A partir de la información estadística sobre la navegación en
          nuestra página web, podemos mejorar tanto el propio funcionamiento de
          la página como los distintos servicios que ofrece. Por tanto, estas
          cookies <strong>no tienen una finalidad publicitaria,</strong> sino
          que únicamente sirven para que nuestra página web funcione mejor,
          adaptándose a nuestros usuarios en general. Activándolas contribuirás
          a dicha mejora continua. <br />
          <strong>
            Puedes activar o desactivar estas cookies marcando la casilla
            correspondiente,
          </strong>{" "}
          estando desactivadas por defecto.
        </p>

        <p>
          - <strong>Cookies de Funcionalidad y Personalización:</strong> Las
          cookies de funcionalidad nos permiten recordar tus preferencias, para
          personalizar a tu medida determinadas características y opciones
          generales de nuestra página web, cada vez que accedas a la misma (por
          ejemplo, el idioma en que se te presenta la información, las secciones
          marcadas como favoritas, tu tipo de navegador, etc.). Por tanto, este
          tipo de cookies <strong>no tienen una finalidad publicitaria,</strong>{" "}
          sino que activándolas mejorarás la funcionalidad de la página web (por
          ejemplo, adaptándose a tu tipo de navegador) y la personalización de
          la misma en base a tus preferencias (por ejemplo, presentando la
          información en el idioma que hayas escogido en anteriores ocasiones),
          lo cual contribuirá a la facilidad, usabilidad y comodidad de nuestra
          página durante tu navegación. <br />
          <strong>
            Puedes activar o desactivar estas cookies marcando la casilla
            correspondiente,
          </strong>{" "}
          estando desactivadas por defecto.
        </p>
        <p>
          - <strong>Cookies de Publicidad:</strong> Las cookies de publicidad
          nos permiten la gestión de los espacios publicitarios incluidos en
          nuestra página web en base a criterios como el contenido mostrado o la
          frecuencia en la que se muestran los anuncios. Así, por ejemplo, si se
          te ha mostrado varias veces un mismo anuncio en nuestra página web, y
          no has mostrado un interés personal haciendo clic sobre él, este no
          volverá a aparecer. En resumen, activando este tipo de cookies, la
          publicidad mostrada en nuestra página web será más útil y diversa, y
          menos repetitiva. <br />
          <strong>
            Puedes activar o desactivar estas cookies marcando la casilla
            correspondiente,
          </strong>{" "}
          estando desactivadas por defecto.
        </p>
        <p>
          - <strong>Cookies de Publicidad Comportamental:</strong> Las cookies
          de publicidad comportamental nos permiten obtener información basada
          en la observación de tus hábitos y comportamientos de navegación en la
          web, a fin de poder mostrarte contenidos publicitarios que se ajusten
          mejor a tus gustos e intereses personales. Para que lo entiendas de
          manera muy sencilla, te pondremos un ejemplo ficticio: si tus últimas
          búsquedas en la web estuviesen relacionadas con literatura de
          suspense, te mostraríamos publicidad sobre libros de suspense. Por
          tanto, activando este tipo de cookies, la publicidad que te mostremos
          en nuestra página web no será genérica, sino que estará orientada a
          tus búsquedas, gustos e intereses, ajustándose por tanto
          exclusivamente a ti. <br />
          <strong>
            Puedes activar o desactivar estas cookies marcando la casilla
            correspondiente,
          </strong>{" "}
          estando desactivadas por defecto.
        </p>
        <h6>
          • ¿QUÉ TIPO DE COOKIES SE UTILIZAN ACTUALMENTE EN NUESTRA PÁGINA WEB?
        </h6>
        <p>
          A continuación, procedemos a informarte sobre todos los tipos de
          cookies que actualmente se utilizan en nuestra página web y la
          finalidad de cada una de las mismas. <br />-
          <strong>Cookies de Análisis:</strong> Las cookies de análisis nos
          permiten estudiar la navegación de los usuarios de nuestra página web
          en general (por ejemplo, qué secciones de la página son las más
          visitadas, qué servicios se usan más y si funcionan correctamente,
          etc.). <br />- <strong> Cookies Técnicas</strong> : Las cookies
          técnicas son estrictamente necesarias para que nuestra página web
          funcione y puedas navegar por la misma. Este tipo de cookies son las
          que, por ejemplo, nos permiten identificarte, darte acceso a
          determinadas partes restringidas de la página si fuese necesario, o
          recordar diferentes opciones o servicios ya seleccionados por ti, como
          tus preferencias de privacidad.
        </p>
        <h6>¿QUÉ PUEDES HACER CON LAS COOKIES?</h6>
        <p>
          Cuando accedes por primera vez a nuestra página web, se te muestra una
          ventana en la que te informamos que las cookies pueden ser de varios
          tipos:
        </p>
        <ul>
          <li>
            -Las cookies técnicas son necesarias para que nuestra página web
            pueda funcionar, no necesitan de tu autorización y son las únicas
            que tenemos activadas por defecto.
          </li>
          <li>
            - El resto de cookies sirven para mejorar nuestra página, para
            personalizarla en base a tus preferencias, o para poder mostrarte
            publicidad ajustada a tus búsquedas, gustos e intereses personales.
            Puedes aceptar todas estas cookies pulsando el botón ACEPTAR,
            rechazarlas pulsando el botón RECHAZAR o configurarlas clicando en
            el apartado CONFIGURACIÓN de COOKIES.
          </li>
        </ul>
        <p>
          Informarte también que, una vez que hayas activado cualquier tipo de
          cookies, tienes la posibilidad de desactivarlas en el momento que
          desees, con el simple paso de desmarcar la casilla correspondiente en
          el apartado CONFIGURACIÓN de COOKIES de nuestra página web. Siempre
          será tan sencillo para ti activar nuestras cookies propias como
          desactivarlas.
        </p>
        <p>
          Recordarte asimismo que, a través de la configuración de tu navegador,
          puedes bloquear o alertar de la presencia de cookies, si bien dicho
          bloqueo puede afectar al correcto funcionamiento de las distintas
          funcionalidades de nuestra página web en el caso de las cookies
          técnicas necesarias.
        </p>
        <p>
          Por último, indicarte que, si activas cookies de terceros (empresas
          externas que prestan servicios para nuestra página web) y
          posteriormente deseas desactivarlas, podrás hacerlo de dos formas:
          usando las herramientas de desactivación de cookies de tu navegador o
          a través de los propios sistemas habilitados por dichos proveedores
          externos.
        </p>
        <p>
          Para que te sea mucho más fácil, a continuación, te relacionamos una
          serie de enlaces a las pautas de desactivación de cookies de los
          navegadores de uso común:
        </p>
        <ul>
          <li>
            <a
              href="https://support.google.com/chrome/answer/95647?hl=es"
              target="_blank"
              rel="noreferrer"
            >
              Google Chrome
            </a>
          </li>
          <li>
            <a
              href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias"
              target="_blank"
              rel="noreferrer"
            >
              Mozilla Firefox
            </a>
          </li>
          <li>
            <a
              href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies"
              target="_blank"
              rel="noreferrer"
            >
              Internet Explorer
            </a>
          </li>
          <li>
            <a
              href="https://support.apple.com/es-es/guide/safari/sfri11471/mac"
              target="_blank"
              rel="noreferrer"
            >
              Safari
            </a>
          </li>
          <li>
            <a
              href="https://support.apple.com/es-es/HT201265"
              target="_blank"
              rel="noreferrer"
            >
              Safari para IOS (iPhone y iPad)
            </a>
          </li>
          <li>
            <a
              href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=es"
              target="_blank"
              rel="noreferrer"
            >
              Google Chrome para Android
            </a>
          </li>
          <li>
            <a
              href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DiOS&hl=es"
              target="_blank"
              rel="noreferrer"
            >
              Google Chrome para Iphone y Ipad
            </a>
          </li>
        </ul>
        <p>Muchas gracias por tu colaboración</p>
      </section>
      <Inmoadal />
    </>
  );
};

export default PoliticaDeCookies;
